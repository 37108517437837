<template>
	<div class="container">
		<div class="left-menu" :style="{
        width: !isCollapse ? '12%' : '4.5%',
        textAlign: !isCollapse ? 'left' : 'center',
      }">
			<left-list :leftIndex="'10'" :isCollapse="isCollapse"></left-list>
		</div>
		<div class="right-content" :style="{ width: !isCollapse ? '88%' : '95.5%' }">
			<div class="top-nav">
				<top-header @openMenu="openMenu" :isCollapse="isCollapse"></top-header>
			</div>
			<div class="left-list">
				<div class="left-list-title">
					<span>矿山管理</span>
				</div>
				<div class="left-list-item left-list-active" @click="leftClick('0')">
					<span>矿山列表</span>
				</div>
        <!--
				<div class="left-list-item" @click="leftClick('1')">
					<span>备件分类</span>
				</div>
				<div class="left-list-item" @click="leftClick('2')">
					<span>备件列表</span>
				</div>
				-->
			</div>
			<div class="right-next">
				<div class="title">
					<span>{{title}}</span>
				</div>
				<div class="edit-form">
					<div class="item">
						<div class="label">矿山名称：</div>
						<el-input placeholder="矿山名称" v-model="editForm.name"></el-input>
					</div>
					<div class="item">
						<div class="label">矿山地理经度：</div>
						<el-input placeholder="矿山地理经度" v-model="editForm.longitude"></el-input>
					</div>
					<div class="item">
						<div class="label">矿山地理纬度：</div>
						<el-input placeholder="矿山地理纬度" v-model="editForm.latitude"></el-input>
					</div>
					<!-- <div class="item">
						<div class="label">矿山所在城市：</div>
						<el-input placeholder="矿山所在城市" v-model="editForm.city"></el-input>
					</div> -->
					<!-- <div class="item">
						<div class="label">城市跳转地址：</div>
						<el-input placeholder="城市跳转地址" v-model="editForm.cityUrl"></el-input>
					</div> -->
					<!-- <div class="item">
						<div class="label">矿山详细地址：</div>
						<el-input placeholder="矿山详细地址" v-model="editForm.address"></el-input>
					</div> -->
					<!-- <div class="item">
						<div class="label">矿山详细地址跳转地址：</div>
						<el-input placeholder="矿山详细地址跳转地址" v-model="editForm.addressUrl"></el-input>
					</div> -->
					<div class="item">
						<div class="label">矿山图片:</div>
						<el-upload class="avatar-uploader" action="url" :show-file-list="false" ref="upload"
							:on-change="uploadChange" :headers="uploadHeaders" :auto-upload="false"
							:http-request="uploadFile">
							<img v-if="editForm.url" :src="editForm.url" class="avatar" />
							<img v-else-if="editForm.url2" :src="this.$store.state.imaUrl + editForm.url2"
								class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>
					<!-- <div class="item">
						<div class="label">矿山信息:</div>
						<div class="yield" v-for="(item,index) in mineinfoList" :key="index">
							<div class="commodity">
								<div class="label">Stage</div>
								<el-input placeholder="Stage" v-model="item.stage"></el-input>
							</div>
							<div class="commodity">
								<div class="label">mine Type</div>
								<el-input placeholder="mine Type" v-model="item.mine_type"></el-input>
							</div>
							<div class="commodity">
								<div class="label">Commodities</div>
								<el-input placeholder="Commodities" v-model="item.commodities"></el-input>
							</div>
							<div class="commodity">
								<div class="label">mining Method</div>
								<el-input placeholder="mining Method" v-model="item.mining_method"></el-input>
							</div>
							<div class="commodity">
								<div class="label">Processing</div>
								<el-input placeholder="Processing" v-model="item.processing"></el-input>
							</div>
							<div class="commodity">
								<div class="label">mine Life</div>
								<el-input placeholder="mine Life" v-model="item.mine_life"></el-input>
							</div>
						</div>
					</div> -->
					<div class="item">
						<div class="label">公司名称:</div>
						<el-select v-model="editForm.corporateid" clearable placeholder="请选择公司">
							<el-option v-for="item in options" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
					<div class="item">
						<div class="label">所属洲:</div>
						<el-select v-model="editForm.continent" clearable placeholder="请选择所属洲">
							<el-option v-for="item in continentList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
					<!-- <div class="item">
						<div class="label">矿物特性:</div>
						<quill-editor class="editor" ref="myTextEditor" v-model="editForm.characteristic"
							:options="editorOption">
						</quill-editor>
					</div> -->
					<!-- <div class="item">
						<div class="label">采矿方式:</div>
						<quill-editor class="editor" ref="myTextEditor" v-model="editForm.mode" :options="editorOption">
						</quill-editor>
					</div> -->
					<!-- <div class="item">
						<div class="label">加工处理:</div>
						<quill-editor class="editor" ref="myTextEditor" v-model="editForm.machining"
							:options="editorOption">
						</quill-editor>
					</div> -->
					<!-- <div class="item">
						<div class="label">研磨工段:</div>
						<quill-editor class="editor" ref="myTextEditor" v-model="editForm.grinding"
							:options="editorOption">
						</quill-editor>
					</div> -->
					<!-- <div class="item">
						<div class="label">磨机信息:</div>
						<quill-editor class="editor" ref="myTextEditor" v-model="editForm.millinfo"
							:options="editorOption">
						</quill-editor>
					</div> -->
					<!-- <div class="item">
						<div class="label">预期产量:</div>
						<div class="yield" v-for="(item,index) in commodityList" :key="index">
							<div class="commodity">
								<div class="label">Commodity</div>
								<el-input placeholder="Commodity" v-model="item.commodity"></el-input>
								<el-button type="primary" @click="commodityAdd">添加</el-button>
							</div>
							<div class="commodity">
								<div class="label">Units</div>
								<el-input placeholder="Units" v-model="item.units"></el-input>
							</div>
							<div class="commodity">
								<div class="label">Avg.Annual</div>
								<el-input placeholder="Avg.Annual" v-model="item.avg_Annual"></el-input>
							</div>
						</div>
					</div> -->
					<!-- <div class="item">
						<div class="label">储量:</div>
						<div class="yield" v-for="(item,index) in reservesList" :key="index">
							<div class="commodity">
								<div class="label">Category</div>
								<el-input placeholder="Category" v-model="item.category"></el-input>
								<el-button type="primary" @click="reservesAdd">添加</el-button>
							</div>
							<div class="commodity">
								<div class="label">Tonnage</div>
								<el-input placeholder="Tonnage" v-model="item.tonnage"></el-input>
							</div>
							<div class="commodity">
								<div class="label">Commodity</div>
								<el-input placeholder="Commodity" v-model="item.commodity"></el-input>
							</div>
							<div class="commodity">
								<div class="label">Grade</div>
								<el-input placeholder="Grade" v-model="item.grade"></el-input>
							</div>
							<div class="commodity">
								<div class="label">contained Metal</div>
								<el-input placeholder="contained Metal" v-model="item.contained_metal"></el-input>
							</div>
						</div>
					</div> -->
					<!-- <div class="item">
						<div class="label">3D效果图:</div>
						<el-upload class="u_img" action="url" accept=".html" ref="fileupload"
							:on-change="fileuploadChange" :headers="uploadHeaders" :auto-upload="false"
							:http-request="fileuploadFile" style="margin-top: 10px;">
							<el-button type="primary">选择文件</el-button>
							<div slot="tip" class="el-upload__tip">只能上传html格式文件</div>
						</el-upload>
					</div> -->
					<div class="item submit">
						<el-button type="primary" @click="submitUpload">保存添加</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import leftList from "../../components/leftList.vue";
	import topHeader from "../../components/header.vue"
	export default {
		components: {
			leftList,
			topHeader
		},
		inject: ["reload"],
		data() {
			return {
				title: '添加矿山',
				isCollapse: false,
				uploadHeaders: {
					"Remember-Token": localStorage.getItem("rememberToken"),
				},
				commodityList: [{
					commodity: '',
					units: '',
					avg_Annual: ''
				}],
				reservesList: [{
					category: '',
					tonnage: '',
					commodity: '',
					grade: '',
					contained_metal: ''
				}],
				mineinfoList: [{
					stage: '',
					mine_type: '',
					commodities: '',
					mining_method: '',
					processing: '',
					mine_life: ''
				}],
				options: [],
				continentList: [{
					id: 1,
					name: '亚洲'
				}, {
					id: 2,
					name: '欧洲'
				}, {
					id: 3,
					name: '南美洲'
				}, {
					id: 4,
					name: '北美洲'
				}, {
					id: 5,
					name: '非洲'
				}, {
					id: 6,
					name: '南极洲'
				}, {
					id: 7,
					name: '大洋洲'
				}],
				editorOption: {
					modules: {
						toolbar: [
							["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
							["blockquote", "code-block"], // 引用  代码块
							[{
								header: 1
							}, {
								header: 2
							}], // 1、2 级标题
							[{
								list: "ordered"
							}, {
								list: "bullet"
							}], // 有序、无序列表
							[{
								script: "sub"
							}, {
								script: "super"
							}], // 上标/下标
							[{
								indent: "-1"
							}, {
								indent: "+1"
							}], // 缩进
							// [{'direction': 'rtl'}],                         // 文本方向
							[{
								size: ["small", false, "large", "huge"]
							}], // 字体大小
							[{
								header: [1, 2, 3, 4, 5, 6, false]
							}], // 标题
							[{
								color: []
							}, {
								background: []
							}], // 字体颜色、字体背景颜色
							[{
								font: []
							}], // 字体种类
							[{
								align: []
							}], // 对齐方式
							["clean"], // 清除文本格式
							["link", "image"] // 链接、图片、视频
						], //工具菜单栏配置
					},
					placeholder: '请输入内容', //提示
					readyOnly: false, //是否只读
					theme: 'snow', //主题 snow/bubble
					syntax: true, //语法检测
				},
				editForm: {
					name: '',
					corporateid: '',
					latitude:'', //纬度
					longitude:'', //经度
					address:'',
					addressUrl:'',
					city:'',
					cityUrl:'',
					continent: '',
					characteristic: '',
					grinding:'',
					mode: '',
					machining: '',
					url: '',
					url2: '',
					file: '',
					millinfo:''
				},
				formDate: '',
				fileDate: '',
			};
		},
		created() {
			if (this.$route.query.id) {
				this.title = '编辑矿山';
				this.getinfo();
			}
			this.getCompanylist();
		},
		methods: {
			getinfo(){
				this.$get('mine/get_details',{
					id:this.$route.query.id
				}).then(res =>{
					console.log(res);
					this.editForm.name = res.data.data.name;
					let lat = res.data.data.location.split(',');
					this.editForm.latitude = lat[1];
					this.editForm.longitude = lat[0];
					// this.editForm.lat = res.data.data.location;
					// this.editForm.city = res.data.data.city;
					// this.editForm.cityUrl = res.data.data.city_url;
					// this.editForm.address = res.data.data.project_contacts;
					// this.editForm.addressUrl = res.data.data.contacts_url;
					this.editForm.url2 = res.data.data.mining_img;
					// this.mineinfoList = JSON.parse(res.data.data.over_view);
					this.editForm.corporateid = res.data.data.company_id;
					this.editForm.continent = res.data.data.continents;
					// this.editForm.characteristic = res.data.data.deposit_type;
					// this.editForm.mode = res.data.data.mining_methods;
					// this.editForm.machining = res.data.data.mining_methods;
					// this.editForm.grinding = res.data.data.grinding_section;
					// this.editForm.millinfo = res.data.data.mill_info;
					// this.commodityList = JSON.parse(res.data.data.projected_production);
					// this.reservesList = JSON.parse(res.data.data.reserves);
					// this.editForm.file = res.data.data.html_3D;
				})
			},
			submitUpload() {
				let _this = this;
				if (this.editForm.name == '') {
					this.$notify.error({
						title: '提示',
						message: '请填写矿山名称'
					});
					return false;
				}
				if (this.editForm.longitude == '') {
					this.$notify.error({
						title: '提示',
						message: '请填写矿山地理经度'
					});
					return false;
				}
				if (this.editForm.latitude == '') {
					this.$notify.error({
						title: '提示',
						message: '请填写矿山地理纬度'
					});
					return false;
				}
				// if (this.editForm.city == '') {
				// 	this.$notify.error({
				// 		title: '提示',
				// 		message: '请填写矿山所在城市'
				// 	});
				// 	return false;
				// }
				// if (this.editForm.cityUrl == '') {
				// 	this.$notify.error({
				// 		title: '提示',
				// 		message: '请填写矿山所在城市跳转路径'
				// 	});
				// 	return false;
				// }
				// if (this.editForm.address == '') {
				// 	this.$notify.error({
				// 		title: '提示',
				// 		message: '请填写矿山详细地址'
				// 	});
				// 	return false;
				// }
				// if (this.editForm.addressUrl == '') {
				// 	this.$notify.error({
				// 		title: '提示',
				// 		message: '请填写矿山详细地址跳转路径'
				// 	});
				// 	return false;
				// }
				if (this.editForm.url == '' && this.editForm.url2 == '') {
					this.$notify.error({
						title: "提示",
						message: "请上传矿山图片！",
					});
					return false;
				}
				// if (this.mineinfoList[0].stage == '') {
				// 	this.$notify.error({
				// 		title: "提示",
				// 		message: "stage内容不可为空！",
				// 	});
				// 	return false;
				// }
				// if (this.mineinfoList[0].mine_type == '') {
				// 	this.$notify.error({
				// 		title: "提示",
				// 		message: "mine type内容不可为空！",
				// 	});
				// 	return false;
				// }
				// if (this.mineinfoList[0].commodities == '') {
				// 	this.$notify.error({
				// 		title: "提示",
				// 		message: "commodities内容不可为空！",
				// 	});
				// 	return false;
				// }
				// if (this.mineinfoList[0].mining_method == '') {
				// 	this.$notify.error({
				// 		title: "提示",
				// 		message: "mining Method内容不可为空！",
				// 	});
				// 	return false;
				// }
				// if (this.mineinfoList[0].processing == '') {
				// 	this.$notify.error({
				// 		title: "提示",
				// 		message: "processing内容不可为空！",
				// 	});
				// 	return false;
				// }
				// if (this.mineinfoList[0].mine_life == '') {
				// 	this.$notify.error({
				// 		title: "提示",
				// 		message: "mine Life内容不可为空！",
				// 	});
				// 	return false;
				// }
				if (this.editForm.corporateid == '') {
					this.$notify.error({
						title: "提示",
						message: "请选择公司！",
					});
					return false;
				}
				if (this.editForm.continent == '') {
					this.$notify.error({
						title: "提示",
						message: "请选择所在洲！",
					});
					return false;
				}
				// if (this.editForm.characteristic == '') {
				// 	this.$notify.error({
				// 		title: "提示",
				// 		message: "矿物特性不可为空！",
				// 	});
				// 	return false;
				// }
				// if (this.editForm.mode == '') {
				// 	this.$notify.error({
				// 		title: "提示",
				// 		message: "采矿方式不可为空！",
				// 	});
				// 	return false;
				// }
				// if (this.editForm.machining == '') {
				// 	this.$notify.error({
				// 		title: "提示",
				// 		message: "加工处理不可为空！",
				// 	});
				// 	return false;
				// }
				// if (this.editForm.grinding == '') {
				// 	this.$notify.error({
				// 		title: "提示",
				// 		message: "研磨工段不可为空！",
				// 	});
				// 	return false;
				// }
				// if (this.editForm.millinfo == '') {
				// 	this.$notify.error({
				// 		title: "提示",
				// 		message: "磨机信息不可为空！",
				// 	});
				// 	return false;
				// }
				// for(let i = 0; i < this.commodityList.length;i++){
				// 	if(this.commodityList[i].commodity == ''){
				// 		this.$notify.error({
				// 			title: "提示",
				// 			message: "commodity不可为空！",
				// 		});
				// 		return false;
				// 	} 
				// 	if(this.commodityList[i].units == ''){
				// 		this.$notify.error({
				// 			title: "提示",
				// 			message: "units不可为空！",
				// 		});
				// 		return false;
				// 	} 
				// 	if(this.commodityList[i].avg_Annual == ''){
				// 		this.$notify.error({
				// 			title: "提示",
				// 			message: "Avg.Annual不可为空！",
				// 		});
				// 		return false;
				// 	}
				// }
				// for(let i = 0; i < this.reservesList.length;i++){
				// 	if(this.reservesList[i].category == ''){
				// 		this.$notify.error({
				// 			title: "提示",
				// 			message: "category不可为空！",
				// 		});
				// 		return false;
				// 	} 
				// 	if(this.reservesList[i].tonnage == ''){
				// 		this.$notify.error({
				// 			title: "提示",
				// 			message: "tonnage不可为空！",
				// 		});
				// 		return false;
				// 	} 
				// 	if(this.reservesList[i].commodity == ''){
				// 		this.$notify.error({
				// 			title: "提示",
				// 			message: "commodity不可为空！",
				// 		});
				// 		return false;
				// 	}
				// 	if(this.reservesList[i].grade == ''){
				// 		this.$notify.error({
				// 			title: "提示",
				// 			message: "grade不可为空！",
				// 		});
				// 		return false;
				// 	}
				// 	if(this.reservesList[i].contained_metal == ''){
				// 		this.$notify.error({
				// 			title: "提示",
				// 			message: "containedMetal不可为空！",
				// 		});
				// 		return false;
				// 	}
				// }
				// if(this.editForm.file == ''){
				// 	this.$notify.error({
				// 		title: "提示",
				// 		message: "请上传3D效果图",
				// 	});
				// 	return false;
				// }
				this.formDate = new FormData();
				_this.$refs.upload.submit();
				let lat = this.editForm.longitude + ',' + this.editForm.latitude;
				// _this.$refs.fileupload.submit();
				// let reserves = [];
				// let projectedProduction = [];
				// let overView = [];
				this.formDate.append("name", this.editForm.name);
				this.formDate.append("company_id", this.editForm.corporateid);
				this.formDate.append("continents", this.editForm.continent);
				// this.formDate.append("deposit_type", this.editForm.characteristic);
				// this.formDate.append("mining_methods", this.editForm.mode);
				// this.formDate.append("processing", this.editForm.mode);
				this.formDate.append("location", lat);
				// this.formDate.append("mill_info", this.editForm.millinfo);
				// this.formDate.append("grinding_section", this.editForm.grinding);
				// this.formDate.append("city", this.editForm.city);
				// this.formDate.append("project_contacts", this.editForm.address);
				// this.formDate.append("city_url", this.editForm.cityUrl);
				// this.formDate.append("contacts_url", this.editForm.addressUrl);
				// for(let i = 0; i < this.reservesList.length;i++){
				// 	reserves.push(this.reservesList[i]);
				// }
				// this.formDate.append("reserves", JSON.stringify(reserves));
				// for(let i = 0; i < this.commodityList.length;i++){
				// 	projectedProduction.push(this.commodityList[i]);
				// }
				// this.formDate.append("projected_production", JSON.stringify(projectedProduction));
				// overView.push(this.mineinfoList[0]);
				// this.formDate.append("over_view", JSON.stringify(overView));
				let config = {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				};
				if (this.$route.query.id) {
					this.formDate.append("id", this.$route.query.id);
					this.$axios
						.post(this.$store.state.httpUrl + "upd/mine", this.formDate, config)
						.then((res) => {
							if (res.data.status == 200) {
								this.$notify({
									title: "提示",
									message: "操作成功！",
									type: "success",
								});
								this.$router.push("/mine");
							} else {
								this.$notify.error({
									title: "提示",
									message: res.data.message,
								});
							}
							// console.log(res);
						})
						.catch((res) => {
							console.log(res);
						});
				} else {
					this.$axios
						.post(this.$store.state.httpUrl + "add/mine", this.formDate, config)
						.then((res) => {
							if (res.data.status == 200) {
								this.$notify({
									title: "提示",
									message: "操作成功！",
									type: "success",
								});
								this.$router.push("/mine");
							} else {
								this.$notify.error({
									title: "提示",
									message: res.data.message,
								});
							}
							// console.log(res);
						})
						.catch((res) => {
							console.log(res);
						});
				}
			},
			reservesAdd() {
				this.reservesList.push({
					category: '',
					tonnage: '',
					commodity: '',
					grade: '',
					contained_metal: ''
				})
			},
			commodityAdd() {
				this.commodityList.push({
					commodity: '',
					units: '',
					avg_Annual: ''
				})
			},
			getCompanylist() {
				this.$get('company/get_list', {
					num: 999
				}).then(res => {
					console.log(res);
					this.options = res.data.data.data;
				})
			},
			leftClick(index) {
				if (index == 0 && this.$route.path != "/mine") {
					this.$router.push("/mine");
				} else if(index == 1 && this.$route.path != "/minetype"){
					this.$router.push("/minetype");
				} else if(index == 2 && this.$route.path != "/minefile"){
					this.$router.push("/minefile");
				} else {
					this.reload();
				}
			},
			uploadFile(file) {
				this.formDate.append("mining_img", file.file);
				// console.log(this.formDate);
			},
			uploadChange(file, fileList) {
				console.log(file, fileList);
				this.editForm.url = URL.createObjectURL(file.raw);
			},
			fileuploadFile(file) {
				this.formDate.append("html_3D", file.file);
				// console.log(this.formDate);
			},
			fileuploadChange(file, fileList) {
				if (this.beforeUpload_u(file, fileList)) {
					this.editForm.file = URL.createObjectURL(file.raw);
				} else {
					this.editForm.file = '';
					this.$refs.fileupload.clearFiles();
				}
			},
			openMenu() {
				this.isCollapse = !this.isCollapse;
			},
			beforeUpload_u(file, fileList) {
				console.log(fileList);
				var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
				const extension = testmsg === 'html'
				var bool = false;
				if (extension) {
					bool = true;
				} else {
					bool = false;
				}
				if (!extension) {
					this.$notify({
						title: '提示',
						message: '选择文件有误！',
						type: 'warning'
					});
				}
				return bool;
			},
		},
	};
</script>

<style lang="scss">
	.quill-editor {
		margin-top: 20px;
		width: 80%;
	}

	.ql-editor {
		height: 230px;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409eff;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar-uploader {
		display: inline-block;
		vertical-align: top;
	}

	.avatar {
		width: 178px;
		height: 178px;
		// display: block;
	}

	.right-content {
		height: 100vh;
		overflow-x: hidden;
		overflow-y: scroll;
		vertical-align: top;
		display: inline-block;

		&::-webkit-scrollbar {
			display: none;
		}

		.date-search {
			margin-top: 30px;

			.el-button {
				padding: 12px 55px;
			}
		}

		.left-list {
			width: 10%;
			height: 93vh;
			overflow-x: hidden;
			overflow-y: scroll;
			font-size: 14px;
			border-right: 20px solid #f2f2f2;
			display: inline-block;
			text-align: center;

			&::-webkit-scrollbar {
				display: none;
			}

			&-title {
				margin: 20px 0;
				font-size: 16px;
			}

			&-item {
				padding: 15px 0;
				cursor: pointer;
				transition: all 0.2s;

				&:hover {
					color: #fff;
					background-color: #3291f8;
				}
			}

			&-active {
				color: #fff;
				background-color: #3291f8;
			}
		}

		.right-next {
			height: 93vh;
			width: 88.5%;
			overflow-x: hidden;
			overflow-y: scroll;
			border-top: 20px solid #f2f2f2;
			padding: 20px;
			box-sizing: border-box;
			display: inline-block;
			vertical-align: top;

			&::-webkit-scrollbar {
				display: none;
			}
		}

		.title {
			color: #000;
			height: 10px;
			padding-left: 15px;
			line-height: 10px;
			border-left: 5px solid #80c4f8;
			margin-bottom: 10px;
		}

		.edit-form {
			.submit {
				width: 80%;
				text-align: center;

				.el-button {
					width: 150px;
				}
			}

			.item {
				margin-top: 30px;

				.label {
					display: inline-block;
					vertical-align: middle;
					width: 120px;
				}

				.yield {
					margin-left: 30px;
					font-size: 14px;

					.commodity {
						margin-top: 15px;

						.el-button {
							margin-left: 15px;
						}
					}
				}

				.el-input,
				.el-select {
					width: 230px;
				}
			}
		}

		.top-nav {
			display: flex;
			box-sizing: border-box;
			justify-content: space-between;
			padding: 15px;

			.left-icon {
				i {
					cursor: pointer;
					color: #3291f8;
					font-size: 30px;
				}

				.el-icon-refresh-right {
					margin-left: 30px;
				}
			}

			.right-user {
				font-size: 14px;

				span {
					vertical-align: middle;
					margin-right: 20px;
				}

				.colse-btn {
					cursor: pointer;
					vertical-align: middle;
					border: 1px solid #999;
					display: inline-block;
					padding: 5px 20px;
					border-radius: 50px;

					span {
						margin-right: 10px;
					}

					i {
						vertical-align: middle;
					}
				}
			}
		}
	}

	.left-menu {
		display: inline-block;

		.iconyonghuzu {
			font-size: 18px;
			margin: 0 5px;
		}
	}

	.el-menu {
		width: 100%;
		background-color: #20222a;
		height: 100vh;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		background-color: #20222a;
		color: #fff;
		height: 100vh;
		width: 100%;
	}

	.el-menu-item:focus,
	.el-menu-item:hover {
		color: #000;
		background-color: #ecf5ff;
	}

	.el-menu-item {
		color: #fff;

		i {
			color: #3291f8;
		}
	}

	.el-menu-item.is-active {
		background-color: #ecf5ff;
		color: #20222a;
	}

	.menu-h5 {
		margin: 20px;
		text-align: center;
		font-size: 20px;
	}

	.menu-icon {
		width: 100%;
		color: #fff;
		box-sizing: border-box;
		padding: 30px 22px;
	}
</style>
